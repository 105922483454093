import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Input,
  FormGroup,
  FormLabel,
  FormError,
  ButtonRounded,
  DropdownPrimary,
} from '@Calix-Commerce/design-system/components';
import { CircularProgress, Fade } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { authenticationState } from 'state/atoms';
import { SMX_VERSIONS } from 'utils/constants';

const ComponentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 270px;
  padding: 20px;
`;

const Form = styled.form`
  width: 100%;
  min-width: 300px;
`;

const Fieldset = styled.fieldset`
  border: 0;
`;

const StyledButton = styled(ButtonRounded)`
  margin: 10px auto 0;
  width: calc(100% - 8px);
  min-height: 42px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const StyledInput = styled(Input)`
  padding: 0 1.07rem;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 20px;
  color: #44367d;
  text-align: center;
`;

const ThankYouMessage = styled.p`
  text-align: center;
  color: #333;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const StyledDropdownPrimary = styled(DropdownPrimary)`
  max-width: 275px;
  height: 44px;
  > button {
    height: 44px;
  }
`;

const AxosUsageForm = () => {
  const { oracleId } = useRecoilValue(authenticationState);
  const [count, setCount] = useState('');
  const [smxVersion, setSmxVersion] = useState(SMX_VERSIONS[0]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const smxVersionOptions = SMX_VERSIONS.map((version) => ({ id: version, text: version }));

  const onChange = (event) => {
    const rawValue = event.target.value;
    setCount(rawValue);
    const value = parseInt(rawValue);
    setError(isNaN(value) || value < 0);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (count === '' || hasError || loading) {
      return;
    }

    setLoading(true);

    axios
      .request({
        url: process.env.REACT_APP_API_ENDPOINT,
        params: {
          id: oracleId,
          value: parseInt(count),
          source: 'usage-form',
          environment: 'prod',
          smxVersion: smxVersion,
        },
      })
      .finally(() => {
        setSuccess(true);
        setLoading(false);
      });
  };

  return (
    <ComponentWrapper>
      <Title>AXOS Usage Collection</Title>
      {!success ? (
        <Form id="axos-form" onSubmit={onSubmit}>
          <Fieldset id="axos-fieldset" disabled={loading}>
            <FormGroup>
              <FormLabel htmlFor="smx-version">SMx Version</FormLabel>
              <StyledDropdownPrimary
                id="smx-version"
                options={smxVersionOptions}
                selectedOptionId={smxVersion}
                onSelectOption={(option) => setSmxVersion(option.id)}
                showRadios={false}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="usage-count">Usage count</FormLabel>
              <StyledInput
                autoFocus
                id="usage-count"
                name="usage-count"
                type="number"
                error={hasError}
                min={0}
                onChange={onChange}
                value={count}
              />
              {hasError && (
                <FormError id="axos-input-error">Usage must to be 0 or greater.</FormError>
              )}
            </FormGroup>
            <ButtonWrapper>
              <StyledButton
                id="submit-count"
                disabled={count === '' || loading || hasError}
                type="submit"
              >
                {loading ? <CircularProgress color="inherit" size="1.5rem" /> : 'SEND'}
              </StyledButton>
            </ButtonWrapper>
          </Fieldset>
        </Form>
      ) : null}
      <Fade in={success}>
        <ThankYouMessage>
          Thank you. <br />
          The usage was collected successfully.
        </ThankYouMessage>
      </Fade>
    </ComponentWrapper>
  );
};

export { AxosUsageForm };
