import Cookie from 'js-cookie';

import { USER_REQUEST_PATH_COOKIE } from 'utils/constants';

export const notAuthenticatedConfig = {
  title: 'Not Authenticated',
  content: 'Your session has expired. Please click OK to log in again',
  onConfirmation: () => {
    // Redirect to the current url after user login
    Cookie.set(USER_REQUEST_PATH_COOKIE, window.location.href, {
      domain: '.calix.com',
    });
    window.location.replace(process.env.REACT_APP_CALIX_PORTAL_LOGIN_URL);
  },
};
