export const BUGSNAG_ERROR_CLASSES = {
  DEFAULT_INFO: 'Info',
  DEFAULT_ERROR: 'Error',
};

// Cookie names
export const LOGGED_IN_JWT_COOKIE = 'LoggedInUserEcommJWT';
export const USER_REQUEST_PATH_COOKIE = 'user_request_path';

export const SMX_VERSIONS = [
  'SMx-R21.1',
  'SMx-R21.2',
  'SMx-R21.3',
  'SMx-R21.4',
  'SMx-R22.1',
  'SMx-R22.2',
  'SMx-R22.3',
  'SMx-R22.4',
  'SMx-R23.1',
  'SMx-R23.2',
  'SMx-R23.3',
  'SMx-R23.4',
  'SMx-R24.1',
  'SMx-R24.2',
  'SMx-R24.3',
  'SMx-R24.4'
];
  