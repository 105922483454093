import React, { useEffect } from 'react';
import Cookie from 'js-cookie';
import { Modal } from '@Calix-Commerce/design-system/components';
import { useRecoilState } from 'recoil';
import { authenticationState } from 'state/atoms';
import { USER_REQUEST_PATH_COOKIE } from 'utils/constants';
import { notAuthenticatedConfig } from './modalConfigs';

const AuthOverlay = ({ children }) => {
  const [{ isAuthenticated }] = useRecoilState(authenticationState);

  const renderModal = () => {
    return <Modal isOpen position="top" {...notAuthenticatedConfig} />;
  };

  useEffect(() => {
    Cookie.remove(USER_REQUEST_PATH_COOKIE, { domain: '.calix.com' });
  }, []);

  return isAuthenticated ? children : renderModal();
};

export { AuthOverlay };
