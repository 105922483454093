import React from 'react';

import { Footer as BaseFooter, FooterLink } from '@Calix-Commerce/design-system/components';

const Footer = () => {
  const termsLink = process.env.REACT_APP_CALIX_TERMS_URL;

  return (
    <BaseFooter style={{ marginTop: '3.55rem' }}>
      <span>
        <FooterLink href={termsLink}>© Calix | All Rights Reserved</FooterLink>
      </span>
      <span>
        <FooterLink href={termsLink}>Terms and Conditions</FooterLink>
      </span>
      <span>
        <FooterLink href={termsLink}>Privacy Policy</FooterLink>
      </span>
      <span id="trust-arc-placeholder" />
    </BaseFooter>
  );
};

export { Footer };
