import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import momentjs from 'moment';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { authenticationState } from 'state/atoms';
import visions from 'connectors/visions';

const ErrorBoundary = ({ children }) => {
  const authData = useRecoilValue(authenticationState);

  const visionsClient = useMemo(() => visions(authData.userName || 'unknown'), [authData.userName]);

  bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: process.env.REACT_APP_ENV,
    autoCaptureSessions: true,
    networkBreadcrumbsEnabled: true,
    plugins: [new BugsnagPluginReact()],
    metadata: {
      user: {
        id: authData.userName,
        ...authData,
      },
    },
    onError: (event) => {
      event.addMetadata('visions', {
        url: visionsClient.getVideoSessionLink(),
      });

      // Skip setting visions events if visions isn't loaded
      if (visionsClient.isReady) {
        const userMetadata = event.getMetadata('user');
        const errorMessage = event.errors[0].errorMessage;

        const before = momentjs().utc().add(5, 'minutes').toISOString();
        const after = momentjs().utc().subtract(1, 'minutes').toISOString();

        const bugsnagUrl = `${process.env.REACT_APP_BUGSNAG_URL}/timeline?filters[event.since][0]=${after}&filters[event.before][0]=${before}`;

        visionsClient.setVissionsEvent(errorMessage, {
          userName: userMetadata.userName,
          userType: userMetadata.userType,
          userAccountName: userMetadata.fullName,
          endCustomerAccountName: userMetadata.account,
          endCustomerAccountSfId: userMetadata.endCustomerAccountId,
          bugsnagUrl,
        });
      }
    },
  });

  const Bugsnag = bugsnag.getPlugin('react').createErrorBoundary(React);

  return useMemo(() => <Bugsnag>{children}</Bugsnag>, [children]);
};

export { ErrorBoundary };
