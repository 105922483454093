import ReactGTM from 'react-gtm-module';
import { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    ReactGTM.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    });

    window.dataLayer.push({
      event: 'pageOnLoad',
    });
  }, []);

  return null;
};

export { GoogleTagManager };
