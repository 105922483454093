/**
 * Gets a link to the current session
 * @return {String} The link to the video
 */
const getVideoSessionLink = () => {
  let visionsSessionLink = 'Visions is not currently available';

  // Break out if visions is not initialized
  if (!isVisionsInitialized()) {
    return visionsSessionLink;
  }

  // Construct walkme visions session url
  try {
    const sessionId = window.JacoRecorder.state.session.id;
    visionsSessionLink = `https://insights2.walkme.com/backoffice/projects/d05c8248-531d-4290-8d62-7a205bbc4aee/sessions/${sessionId}/replay`;
  } catch (err) {
    const msg = 'Failed to get the link to recorded error';
    console.error(msg);
    console.error(err);
    return msg;
  }

  // Compose link to session recording if session id is available otherwise send a note that the session wasn't recorded.
  return visionsSessionLink;
};

/**
 * Add custom event to visions session
 */
const setVissionsEvent = (title, data) => {
  console.log('Visions Custom Event: ', title, data);
  if (!isVisionsInitialized()) {
    console.error('Visions is not initialized');
    return;
  }
  // 'Error', { 'bugsnagEvent': event.errorMessage }
  window.JacoRecorder.trackEvent(title, data);
};

/**
 * Checks if walkme visions is ready to record the video session
 * @return {Boolean} true when it is initialized
 */
const isVisionsInitialized = () =>
  !!(window.JacoRecorder && window.JacoRecorder.state && window.JacoRecorder.state.isInitialized);

// Creates a promise that will resolve in specified amount of time
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Executes function with the specified number of
// retries and delay inbetween retrys
const retryWithWait = (fn, delay, retry) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((e) => {
        if (retry-- > 0) {
          return wait(delay)
            .then(retryWithWait.bind(null, fn, delay, retry))
            .then(resolve)
            .catch(reject);
        }
        return reject(e);
      });
  });
};

// Initializes Walkme Visions if it is available
const initializeVisions = (userName) => {
  console.info('Trying to initialize visions');
  return new Promise((resolve, reject) => {
    if (!isVisionsInitialized()) {
      if (window.JacoRecorder) {
        window.JacoRecorder.identify(userName);
        window.JacoRecorder.init();
      }
      // console.info('Walkme visions initialization failed');
      reject();
      return;
    }
    console.info('Visions initialzed');
    resolve();
    return;
  });
};

const Visions = (userName) => {
  // The public api to be used for interacting with visions
  let publicApi = {
    isVisionsInitialized,
    getVideoSessionLink,
    setVissionsEvent,
    onReady: null,
    isReady: false,
  };

  // Creates a promise that will resolve once visions is initialized
  publicApi.onReady = new Promise((resolve, reject) => {
    // Attempts to initilize visions 10 times with
    // a 1 second wait inbetween attempts
    retryWithWait(() => initializeVisions(userName), 1000, 10)
      .then(() => {
        publicApi.isReady = true;
        resolve();
      })
      .catch(() => reject('Visions failed to initialize'));
  }).catch((e) => console.log(e));

  // Public api
  return publicApi;
};

export default Visions;
