import React from 'react';
import calixLogoSrc from '@Calix-Commerce/design-system/assets/img/calix-logo.png';
import { Header as BaseHeader, HeaderContainer } from '@Calix-Commerce/design-system/components';

const Header = () => (
  <BaseHeader>
    <HeaderContainer>
      <img src={calixLogoSrc} alt="Calix Logo" />
    </HeaderContainer>
  </BaseHeader>
);

export { Header };
