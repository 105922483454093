import Cookie from 'js-cookie';
import { defaultAuthenticationState } from 'state/defaults';
import { LOGGED_IN_JWT_COOKIE } from 'utils/constants';

const parseJwt = (token) => {
  let parsedData = {};

  try {
    const base64Jwt = token.split('.')[1].replace('-', '+').replace('_', '/');
    parsedData = JSON.parse(window.atob(base64Jwt));
  } catch (e) {}

  return parsedData;
};

export const getLoggedInJwtCookie = () => Cookie.get(LOGGED_IN_JWT_COOKIE);

export const getAuthFromStorage = () => {
  const loggedInUserEcommJWT = getLoggedInJwtCookie();

  // If the cookie is missing the user is not isAuthenticated
  // so we will do nothing
  if (!loggedInUserEcommJWT) {
    console.error('Missing Auth Token');
    return defaultAuthenticationState;
  }

  // Get jwt data object
  const parsedAuthData = parseJwt(loggedInUserEcommJWT);

  if (
    !parsedAuthData.Account ||
    !parsedAuthData.userType ||
    !parsedAuthData.AccountSFid ||
    !parsedAuthData.ECommerceAccess
  ) {
    console.error('Auth Token Missing Required Data', parsedAuthData);
    return defaultAuthenticationState;
  }

  // Return an object with the auth data
  return {
    isAuthenticated: true,
    account: parsedAuthData.Account,
    accountId: parsedAuthData.AccountSFid,
    endCustomerAccountId: parsedAuthData.AccountSFid || '',
    ecomAccess: parsedAuthData.EcomAccess && parsedAuthData.EcomAccess.toLowerCase() === 'true',
    ecomCheckout:
      parsedAuthData.EcomCheckout && parsedAuthData.EcomCheckout.toLowerCase() === 'true',
    eCommerceAccess: parsedAuthData.ECommerceAccess || [],
    userSFid: parsedAuthData.UserSFid,
    fullName: `${parsedAuthData.FirstName} ${parsedAuthData.LastName}`,
    email: parsedAuthData.email,
    contactId: parsedAuthData.ContactId,
    userType: parsedAuthData.userType,
    userName: parsedAuthData.username,
    jwt: loggedInUserEcommJWT,
    oracleId: parsedAuthData.OracleId,
  };
};
