export const defaultAuthenticationState = {
  isAuthenticated: false,
  account: '',
  accountId: '',
  endCustomerAccountId: '',
  varAccountId: '',
  ecomAccess: false,
  ecomCheckout: false,
  eCommerceAccess: [],
  userSFid: '',
  userType: '',
  userName: '',
  oracleId: '',
};
