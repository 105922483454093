import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const sendPageView = ({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
};

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    const debugOn = process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true';

    ReactGA.initialize(trackingId, { debug: debugOn });
  }, []);

  useEffect(() => {
    sendPageView(location);
  }, [location]);

  return null;
};

export { GoogleAnalytics };
