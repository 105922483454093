import React from 'react';
import { RecoilRoot } from 'recoil';
import styled from '@emotion/styled';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider, GlobalStyles } from '@Calix-Commerce/design-system/globals';
import {
  Header,
  Footer,
  ErrorBoundary,
  GoogleAnalytics,
  AuthOverlay,
  GoogleTagManager,
} from 'components';
import { AxosUsageForm } from 'pages';
import { initializeState } from 'state/initializeState';

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const App = () => {
  const loadGA = process.env.REACT_APP_GOOGLE_ANALYTICS_ID !== 'false';

  return (
    <RecoilRoot initializeState={initializeState}>
      <ErrorBoundary>
        <ThemeProvider>
          <GlobalStyles />
          <AppStyled>
            <Router basename="/axos-usage">
              {loadGA ? <GoogleAnalytics /> : null}
              <AuthOverlay>
                <GoogleTagManager />
                <Header />
                <MainContent>
                  <Switch>
                    <Route exact path="/" component={AxosUsageForm} />
                    <Redirect to="/" />
                  </Switch>
                </MainContent>
                <Footer />
              </AuthOverlay>
            </Router>
          </AppStyled>
        </ThemeProvider>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export default App;
